<template>
  <div class="d-flex flex-column ml10">
    <div class="d-flex align-items-center">
    <p class="fs8 cA3 text-overflow">跳转至</p>
    <el-select
        v-model="btnText"
        style="width: 150px;"
        placeholder="请选择"
        @change="select_tap($event)"
      >
        <el-option
          v-for="(v) in optionList"
          :key="v.text"
          :label="v.text"
          :value="v.text"
        />
      </el-select>
    </div>

      <el-button size="small"
                 style="width: 120px;margin-top:10px;"
                 type="primary"
                 v-if="typeFiltration"
                 @click="dialogShow_tap">
            {{'选择' + btnText}}
      </el-button>
      <!-- 商品选择 -->
      <goodsList :memberData="goodsList"
                 :dialogWidth="'60%'"
                 :title="btnText"
                 :loading="loading"
                 :dialogTableVisible="dialogTableVisible"
                 :total="total"
                 :selectType="selectType"
                 :currentSelect="currentSelect"
                 @dialogClose="dialogClose"
                 @onConfirmShop="onConfirmShop"
                 @dialogCurrentChange1="dialogCurrentChange1"
                 @dialogCurrentChange="dialogCurrentChange">
      </goodsList>
      <div class="flex-a-b-c w140 flex-wrap-y pt10" v-if="goodsType == 'classify'">
        <div class="fs6 w100p text-overflow">
          分类名称：{{goods.name}}
        </div>
        <div class="fs6 w100p text-overflow">数量：{{goods.goodsNum}}</div>
      </div>
      <div class="flex-a-b-c w140 flex-wrap-y pt10" v-if="goodsType == 'appointment'">
        <div class="fs6 w100p text-overflow mb10">
          商品名称：{{goods.companyName}}
        </div>
        <div class="fs6 w100p text-overflow">预约价格：{{goods.price}}元</div>
      </div>
      <div class="flex-a-b-c w140 flex-wrap-y pt10" v-if="goodsType == 'goods'">
        <div class="fs6 w100p text-overflow mb10">
          商品名称：{{goods.goodsName}}
        </div>

        <div class="fs6 w100p text-overflow" v-if="goods.assembleId">商品价格：{{(goods.minAssemblePrice/100)+'~ '+(goods.maxAssemblePrice/100)}}元</div>
        <div class="fs6 w100p text-overflow" v-else-if="goods.killId">商品价格：{{(goods.minKillPrice/100)+'~ '+(goods.maxKillPrice /100)}}元</div>
        <div class="fs6 w100p text-overflow" v-else-if="!goods.assembleId && !goods.killId && goods.price">商品价格：{{(goods.price/100)+'~ '+(goods.maxPrice/100)}}元</div>
        <div class="fs6 w100p text-overflow" v-else>商品价格：{{(goods.minPrice/100)+'~ '+(goods.maxPrice/100)}}元</div>

      </div>
  </div>
</template>

<script>
import {
  getGoodsInfoPCShop,
} from "@/api/miniProgram";
import {
  fetchAppointmentGoodsList,
} from "@/api/companyManage.js";
import {
  selectGoodsTypeInfoPC,
  // @ts-ignore
} from "@/api/coupon.js";
import goodsList from "./goodsList.vue";
import { getDataTimeSec } from "@/utils";
export default {
  props:{
    index: {
      type: Number,
    },
    type: {
      type: String,
    },
    goodsData: {

    },
    currentSelect:{
      type: Number,
      default:null,
    }
  },

  data() {
    return {
      optionList:[
        {
          text: "无",
        },
        {
          text: "商品分类",
          type:'classify'
        },
        {
          text: "普通商品",
          type:'isOrdinary'
        },
        {
          text: "秒杀商品",
          type:'isKill'
        },
        {
          text: "拼团商品",
          type:'isAssemble'
        },
        {
          text: "分销商品",
          type:'isDistribution'
        },
        {
          text: "名片页",
          isPages:true,
          pagePath: "pages/index/main",
        },
        {
          text: "媒体页",
          isPages:true,
          pagePath: "pages/media/index",
        },
        {
          text: "商城页",
          isPages:true,
          pagePath: "pages/Products/main",
        },
        {
          text: "动态页",
          isPages:true,
          pagePath: "pages/Dynamic/main",
        },
        {
          text: "官网页",
          isPages:true,
          pagePath: "pages/WebSite/main",
        },
      ],
      btnText:'无',
      dialogTableVisible:false,
      pageSize: 10, //显示条数
      currentPage:1,
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [''],
      selectType:'',
      typeFiltration:'',
      goods:'',
      goodsType:'',
      currentList:''
    };
  },
  components:{
    goodsList,
  },
  created(){
    if(this.type == 'appointment'){
      this.optionList[2].text = '预约商品'
      this.optionList[2].type = 'appointment'
      this.optionList.splice(3,3)
      this.optionList.splice(1,1)
    }
  },
  watch:{
    btnText(val){
      if(this.filtration.includes(val)){
        this.$set(this,'typeFiltration',false)
      }else{
        this.$set(this,'typeFiltration',true)
      }
    },
    goodsData:{
      handler(newVal,old){
        console.log(newVal)
        this.currentList = newVal
        if(newVal.page && newVal.page.text){
          this.btnText = newVal.page.text
        }
        if(newVal.goods){
          if(newVal.goods.isDistribution){
            this.goods = newVal.goods
            this.selectType = 'isDistribution'
            this.goodsType = 'goods'
            this.btnText = '分销商品'
          }else if(newVal.goods.isAssemble){
            this.btnText = '拼团商品'
            this.selectType = 'isAssemble'
            this.goods = newVal.goods
            this.goodsType = 'goods'
          }else if(newVal.goods.isKill){
            this.btnText = '秒杀商品'
            this.selectType = 'isKill'
            this.goods = newVal.goods
            this.goodsType = 'goods'
          }else if(newVal.goods.companyName && newVal.goods.goodstypeId){
            this.btnText = '商品分类'
            this.goods = newVal.goods
            this.selectType = 'classify'
            this.goodsType = 'classify'
          }else{
            this.btnText = '普通商品'
            this.selectType = 'isOrdinary'
            this.goods = newVal.goods
            this.goodsType = 'goods'
          }
        }
      },
      immediate:true
    },

  },
  computed:{
    filtration(){
      let arr = [
        '无',
        '名片页',
        '媒体页',
        '商城页',
        '动态页',
        '官网页',
      ]
      return arr
    }
  },

  methods:{
    select_tap(val){
      let than = this
      let data = ''
      this.currentPage = 1
      this.goodsType = '' //置空选择项
      this.goods = [] //置空选择项
      this.goodsList = ['']

      if(this.currentSelect){

        this.$emit('currentSelect_tap',this.currentSelect)
      }
      this.optionList.forEach((v,k) => {
        if(v.text == val){
          data = v
        }
      });
      if(data.type){
        this.btnText = data.text
      }


      if(data.type){
        this.$emit('select_tap',this.index,val,data.type)
        this.selectType = data.type
      }else{

        this.$emit('select_tap',this.index,val,data,'',this.currentSelect)
      }


    },
    addImg(){
      this.optionList.push({
        title:'无',
        img:'',
      })
    },
    queryShop(){
      let data = {
        pageNum: this.currentPage,
        draftType:1,
        pageSize:this.pageSize,
      }
      this.loading = true
      data[this.selectType] = 1
      getGoodsInfoPCShop(data)
      .then(res=>{
        if(res.data){
          this.loading = false;
          let data = res.data
          if(data.pageInfo){
             this.total = data.pageInfo.total
             this.goodsList = data.pageInfo.list
          }else if(data.data.pageInfo){
             this.total = data.data.pageInfo.total
             this.goodsList = data.data.pageInfo.list
          }
        }else{
          this.goodsList = []
        }

      })
      .catch(err=>{
        this.loading = false;
      })
    },
    dialogShow_tap(){

      if(this.selectType && this.selectType !== 'classify' && this.selectType !== 'appointment'){//商品
        this.queryShop()
      }else if(this.selectType == 'classify'){//商品分类
        this.queryClassify()
      }else if(this.selectType == 'appointment'){//预约商品
        this.queryAppointment()
      }
      this.dialogTableVisible = true;

    },
    queryClassify(){
      let data = {
        pageNum: this.currentPage
      }
      this.loading = true
      selectGoodsTypeInfoPC(data)
      .then(res=>{
        if(res.data){
          this.loading = false;
          let data = res.data.pageInfo
          this.total = data.total
          this.goodsList = data.list
        }else{
          this.goodsList = []
        }
      })
      .catch(err=>{

        this.loading = false;
      })
    },
    queryAppointment(){
      let than = this
      let data = {
        pageNum: this.currentPage
      }
      this.loading = true
      fetchAppointmentGoodsList(data)
      .then(res=>{
        if(res.data){
          than.loading = false;
          let data = res.data.pageInfo
          than.total = data.total
          than.goodsList = data.list
          than.goodsList.forEach(v=>{
            v.price = JSON.parse(v.price);
            if(typeof(v.price)=="object"){

              v.price = v.price[0] + '~' + v.price[1]
            }
          })
        }else{
          than.goodsList = []
        }
      })
      .catch(err=>{
      })
    },
    //弹出框关闭
    dialogClose(){
      this.dialogTableVisible = false
    },
    dialogCurrentChange(val){
      this.pageSize = val
      this.queryShop()
    },
    dialogCurrentChange1(val){
      this.currentPage = val
      this.queryShop()
    },
    onConfirmShop(goods,type,currentSelect){

      this.goods = goods
      this.goodsType = type
      this.$emit('select_tap',this.index,this.btnText,this.selectType,goods,currentSelect)
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
